.navbar {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 10px;
    color: #ffffff;
    
}

.navbar-logo {
    height: 60px;
    position: absolute;
    left: 20px;
}

.navbar-list {
    list-style-type: none;
    display: flex;
}

.navbar-item {
    margin-left: 15px;
}

.navbar-link {
    color: #000000;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
}
